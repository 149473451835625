<template>
	<div class="index">
		<heading-image type="large">
			<div v-if="page.vimeoID" ref="headingImageElem">
				<div style="padding: 56.25% 0 0; position: relative">
					<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
						<p class="hero-title">
							{{ defaults[locale].homepage.headingImageTitle }}
						</p>
						<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
					</div>
				</div>
				<video-header :video="page.vimeoID" type="landscape" />
			</div>
			<div v-else ref="headingImageElem">
				<hero-image v-if="page.headingImage">
					<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
						<p class="hero-title">
							{{ defaults[locale].homepage.headingImageTitle }}
						</p>
						<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
					</div>
					<picture>
						<source
							v-if="page.headingImage.imagePortraitWebp"
							:srcset="page.headingImage.imagePortraitWebp"
							media="(max-width: 500px)"
							type="image/webp"
						/>
						<source
							v-if="page.headingImage.imagePortrait"
							:srcset="page.headingImage.imagePortrait"
							media="(max-width: 500px)"
						/>
						<source :srcset="page.headingImage.imageWebp" type="image/webp" />
						<source :srcset="page.headingImage.image" />
						<img :src="page.headingImage.image" :alt="page.headingImage.imageAlt" />
					</picture>
				</hero-image>
			</div>
			<template #scroll>
				<a class="scroll-down" @click="scrollDown()">
					<img src="~/assets/images/arrow-down-white.png" alt="Scroll" />
				</a>
			</template>
		</heading-image>

		<main-content>
			<div class="grid main-intro">
				<div class="intro-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							data-aos="fade-right"
							data-aos-duration="1500"
							data-aos-once="true"
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
					<div
						class="img-background"
						data-aos="fade-right"
						data-aos-delay="900"
						data-aos-duration="1500"
						data-aos-once="true"
					/>
				</div>
				<div class="intro-text" data-aos="fade-left" data-aos-duration="1500" data-aos-once="true">
					<h2 class="subtitle">
						{{ page.subtitle }}
					</h2>
					<h1>{{ page.title }}</h1>
					<div v-parse-links v-html="page.content" />
				</div>
			</div>
		</main-content>

		<promoblocks :items="promoblocksData" />

		<section class="section-reservations">
			<div class="row">
				<div class="columns column8">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.sectionReservationsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.sectionReservationsTitle }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.sectionReservationsContent" />
				</div>
				<div class="columns column4 align-right">
					<a
						v-if="defaults[locale].homepage.sectionReservationsLink"
						:href="defaults[locale].homepage.sectionReservationsLink"
						:target="defaults[locale].homepage.sectionReservationsTarget"
						class="button"
					>
						{{ defaults[locale].homepage.sectionReservationsButton }}
					</a>
				</div>
			</div>
		</section>

		<section v-if="defaults[locale].homepage.promotionBackgroundImage" class="section-promotion">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.promotionsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.promotionsHeader }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.promotionsContent" />
				</div>
			</div>
			<picture>
				<source :srcset="defaults[locale].homepage.promotionBackgroundImage" />
				<img class="promotions-image" :src="defaults[locale].homepage.promotionBackgroundImage" />
			</picture>
		</section>

		<contentblocks :items="page.contentblocks" class="section-contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2 class="subtitle">
							{{ defaults[locale].homepage.sectionContentblocksSubtitle }}
						</h2>
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
					</div>
				</div>
			</template>
		</contentblocks>

		<section v-if="defaults[locale].homepage.contactHeader" class="content-section section-contact contact-info">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.contactSubtitle }}
					</h2>
					<h2>
						{{ defaults[locale].homepage.contactHeader }}
					</h2>
					<div v-parse-links v-html="defaults[locale].homepage.contactContent" />
				</div>
			</div>
			<div class="row">
				<div class="columns column4">
					<h3>{{ defaults[locale].website.restaurantName }}</h3>
					<p>
						{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}<br />
						{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}<br />
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon class="icon" icon="far fa-phone" size="1x" />
							{{ defaults[locale].website.phone }} </a
						><br />
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon class="icon" icon="far fa-envelope" size="1x" />
							{{ defaults[locale].website.email }}
						</a>
					</p>
				</div>
				<div class="columns column8 maps-wrapper">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.044233008176!2d4.882330876986668!3d52.40587454467245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6093a7a8efd35%3A0x90c5a94a76ba3e0!2sHelling%207!5e0!3m2!1snl!2snl!4v1682339085955!5m2!1snl!2snl"
						width="100%"
						height="500"
						frameborder="0"
						style="border: 0"
						allowfullscreen=""
						aria-hidden="false"
						tabindex="0"
					/>
				</div>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>

		<section class="snapwidget">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						<a href="https://www.instagram.com/helling_7/" target="_blank"> @helling_7 </a>
					</h2>
					<h2>Come hang out with us</h2>
				</div>
				<div class="columns column12">
					<iframe
						src="https://snapwidget.com/embed/1034298%22"
						class="snapwidget-widget"
						allowtransparency="true"
						frameborder="0"
						scrolling="no"
						style="border: none; overflow: hidden; width: 100%"
					/>
				</div>
			</div>
		</section>

		<section class="also-visit">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 v-if="defaults[locale].homepage.alsoVisitSubtitle" class="subtitle">
						{{ defaults[locale].homepage.alsoVisitSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.alsoVisitHeader }}</h2>
				</div>
				<div class="columns column12 align-center logo-row">
					<a href="https://www.lagerwal.nl/" target="_blank">
						<img src="~/assets/images/logo-lagerwal.png" loading="lazy" alt="Lagerwal" />
					</a>
				</div>
			</div>
		</section>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

onMounted(() => {
	window.FT && window.FT.widgets.current.parse();
});

const headingImageElem = ref(null);

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const scrollDown = () => {
	const scrollHeight = headingImageElem.value.clientHeight;
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};

useHead({
	script: [
		{
			src: 'https://snapwidget.com/js/snapwidget.js',
		},
	],
});
</script>

<style lang="scss" scoped>
.snapwidget {
	background: $secondary-background-color;
	padding: 75px 0;

	h2 {
		margin: 0 auto 40px;
	}
}

.hero-content {
	position: absolute;
	top: 50%;
	display: flex;
	flex-flow: column nowrap;
	color: #fff;
	max-width: 880px;
	text-align: center;
	z-index: 5;
	left: 20px;
	right: 20px;
	margin: auto;
	transform: translateY(calc(-50% + 100px));
	animation: fade-from-bottom 1.5s ease;

	.hero-title {
		width: 100%;
		font-weight: 700;
		font-family: $heading-font-family;
		color: #fff;
		font-size: 84px;
		margin-bottom: 10px;
		line-height: normal;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	:deep(p) {
		margin: 0 0 10px;
		font-size: 25px;
		line-height: 34px;
	}
}

.scroll-down {
	position: absolute;
	bottom: 50px;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	z-index: 4;
	cursor: pointer;

	&:hover img {
		transform: scale(1.1);
	}

	img {
		width: 47px;
		height: 47px;
		transition: all 0.3s ease-in-out;
	}
}

.maps-wrapper {
	background: url('~/assets/images/maps-bg-rectangle.png') no-repeat center left 30px;
	background-size: 100%;
	padding: 30px 30px 30px 0;
}

.also-visit {
	background: $secondary-background-color;
	padding: 75px 0;

	.logo-row {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;

		a {
			margin: 20px;
		}

		img {
			max-width: 159px;
		}
	}
}

.main-intro {
	max-width: 1000px;
	margin: auto;
	grid-template-columns: 6fr 6fr;
	gap: 80px;

	.intro-text {
		padding-top: 70px;
	}

	.intro-image {
		padding: 0 40px;
		position: relative;

		img {
			position: relative;
			z-index: 2;
		}

		.img-background {
			position: absolute;
			z-index: 1;
			inset: 0;
			background: url('~/assets/images/bg-rectangle-big.png') no-repeat center center;
			background-size: 100%;
		}
	}
}

.section-reservations {
	padding: 100px 0;
	background-color: $primary-background-color;

	.row {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
	}

	.form-wrapper {
		padding: 40px 30px;
		background: #f4efe3;

		.button {
			width: auto;
			margin: 10px 0 0;
			padding: 15px 30px;
		}

		input,
		select,
		textarea {
			color: #fff;
			width: 100%;
			max-width: 500px;
			padding: 20px;
			border: none;
			box-shadow: 0 0 5px rgba(0 0 0 / 10%);
			font-size: 13px;
			font-family: $body-font-family;
		}
	}

	.reservation-form {
		display: flex;
		flex-flow: row wrap;
		gap: 25px;

		> div {
			width: calc(50% - 13px);
		}
	}
}

.section-promotion {
	margin: 0;
	padding: 200px 25px;
	overflow: auto;
	clip-path: inset(0);
	position: relative;

	&::after {
		background: rgb(0 0 0 / 45%);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		content: '';
		z-index: 2;
	}

	.row {
		position: relative;
		z-index: 10;
		color: #fff;
		overflow: hidden;
		max-width: 800px;
	}

	.subtitle {
		color: #fff;
		margin: 0 0 20px;
	}

	.promotions-image {
		position: absolute;
		object-fit: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	h2:not(.subtitle) {
		color: #fff;
	}
}

.section-contentblocks {
	.blocks-header {
		margin-bottom: 70px;
	}
}

.section-contact {
	.row:first-child {
		padding-bottom: 50px;
	}
}

@keyframes fade-from-bottom {
	0% {
		opacity: 0;
		transform: translateY(calc(-30% + 100px));
	}

	66% {
		opacity: 0;
	}

	100% {
		opacity: 1;
		transform: translateY(calc(-50% + 100px));
	}
}

@media (max-width: 1080px) {
	.hero-content {
		max-width: 650px;
	}

	.main-intro {
		grid-template-columns: 100%;

		.intro-text {
			padding-top: 0;
		}
	}

	.intro-image {
		padding: 20px !important;
	}
}

@media (max-width: 900px) {
	.section-reservations {
		.row {
			.column4 {
				width: 100%;
				text-align: center;
			}
		}
	}
}

@media (max-width: 880px) {
	.section-promotion {
		padding: 100px 25px;
	}

	.promotions-image {
		position: absolute;
	}

	.hero-content {
		font-size: 15px;
		padding: 0 10px;

		.hero-title {
			font-size: 30px;
		}

		:deep(p) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	.scroll-down {
		bottom: 25px;
	}

	.section-reservations .reservation-form > div {
		width: 100%;
	}
}
</style>
